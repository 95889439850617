<template>
  <div>
    <div class="c-section">
      <h1 class="c-title2 c-title2--mb16">
        オンラインストアとの連携が解除されました
      </h1>
      <p class="p-linkOnlineStore__unlinkText">
        もう一度、オンラインストアアカウントと連携する場合は下記から連携を行ってください。
      </p>

      <Button
        :href="$customUrlScheme.page('standalone', 'url=/link/onlinestore')"
        style-type="secondary"
        fluid>
        オンラインストアと連携する
      </Button>
      <Button
        :href="$customUrlScheme.page('root/home/1')"
        style-type="tertiary"
        fluid
        flat
        class="p-linkOnlineStore__action">
        トップへ戻る
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$emit('unlinked');
  }
};
</script>
