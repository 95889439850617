var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "c-section" },
      [
        _c("h1", { staticClass: "c-title2 c-title2--mb16" }, [
          _vm._v(" オンラインストアとの連携が解除されました "),
        ]),
        _c("p", { staticClass: "p-linkOnlineStore__unlinkText" }, [
          _vm._v(
            " もう一度、オンラインストアアカウントと連携する場合は下記から連携を行ってください。 "
          ),
        ]),
        _c(
          "Button",
          {
            attrs: {
              href: _vm.$customUrlScheme.page(
                "standalone",
                "url=/link/onlinestore"
              ),
              "style-type": "secondary",
              fluid: "",
            },
          },
          [_vm._v(" オンラインストアと連携する ")]
        ),
        _c(
          "Button",
          {
            staticClass: "p-linkOnlineStore__action",
            attrs: {
              href: _vm.$customUrlScheme.page("root/home/1"),
              "style-type": "tertiary",
              fluid: "",
              flat: "",
            },
          },
          [_vm._v(" トップへ戻る ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }